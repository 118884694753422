export const sortByDate = (a, b) => {
  const parseDate = (dateString) => {
      if (!dateString) {
          return new Date(0); // Return a date at the beginning (e.g., 1970-01-01) for blank dates
      }
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day); // Note: month is 0-indexed in JavaScript Dates
  };

  const dateA = parseDate(a.date);
  const dateB = parseDate(b.date);

  if (isNaN(dateA) && isNaN(dateB)) {
      return 0; // If both dates are blank, consider them equal
  } else if (isNaN(dateA)) {
      return 1; // If only a.date is blank, move it to the end
  } else if (isNaN(dateB)) {
      return -1; // If only b.date is blank, move it to the end
  }

  return dateB - dateA;
};
