import './App.css';
import 'animate.css/animate.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import { NineLivesNavbar } from './components/NineLivesNavbar'
import { HashRouter, Routes, Route } from "react-router-dom";
import { Contact } from './components/Contact'
import { Home } from './components/Home'
import { AdoptACage } from './components/AdoptACage'
import { Footer } from './components/Footer'
import axios from 'axios'
import { Volunteers } from './components/Volunteers';
import { Surrenders } from './components/Surrenders'
import { Help } from './components/Help';
import { Foster } from './components/Foster';
import { Available } from './components/Available';
import Dashboard from './components/Dashboard';
import PreloadAssets from './components/PreloadAssets';
import { useEffect, useState, useContext } from 'react';
import { context } from './context';
import { Spinner } from 'reactstrap';
import { News } from './components/News';
import { sortByDate } from './helpers';
function App() {
  const { appState, setAppState } = useContext(context);
  const [news, setNews] = useState([]);
  useEffect(() => {
    axios.get('https://ninelivesapi.jeffmcgowan.net/api/news')
    .then(res => {
      setNews(res.data)
      console.log(res.data.sort(sortByDate))
      setAppState({
        news: res.data.sort(sortByDate),
        isLoading: false
      })
      console.log(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }, [])
  const assetUrls = [
    require('./assets/arroe.png'),
    require('./assets/cagey.png'),
    require('./assets/captain.png'),
    require('./assets/f4.png'),
    require('./assets/keys1.jpg'),
    require('./assets/keys2.jpg'),
    require('./assets/keys3.jpg'),
    require('./assets/kittens.png'),
    require('./assets/kittens2.png'),
    require('./assets/kitties1.jpg'),
    require('./assets/kitties2.jpg'),
    require('./assets/kitties3.jpg'),
    require('./assets/kitties4.png'),
    require('./assets/NINELIVES.png'),
    require('./assets/ninelives1.jpg'),
    require('./assets/NINELIVES2.png'),
    require('./assets/ninelivesclassiclogo.png'),
    require('./assets/windowkey.png'),
    require('./assets/zeleen.jpg'),
    // Add more asset URLs
  ];
  return (
    <div>
    <PreloadAssets assets={assetUrls}>
    <HashRouter>
    <NineLivesNavbar />
      <div className="d-flex main-page">
          <div className='app-container'>
            { !appState.isLoading ?
          <Routes>
            <Route exact path="/" element={<Home news={news}/>} />
            <Route exact path="/adoptacage" element={<AdoptACage />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/volunteers" element={<Volunteers />} />
            <Route exact path="/surrenders" element={<Surrenders />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/foster" element={<Foster />} />
            <Route exact path="/available" element={<Available />} />
            <Route exact path='/login' element={<Dashboard />} />
            <Route exact path='/news' element={<News />} />
          </Routes>
          : <Spinner />
          }
          </div>
      </div>
      <Footer />
    </HashRouter>
    </PreloadAssets>
    </div>
  );
}

export default App;
